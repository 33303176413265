@tailwind components;

@layer components {
  // layout
  .container {
    @apply max-w-xxl mx-auto px-sm md:px-md;
  }
  // for nested blocks like the main nav/menu
  .container .container {
    @apply px-0;
  }
  .section {
    @apply py-10 md:py-10-lg;

    // targets the section after a section with the same background colour 
    &.bg-blue + &.bg-blue,
    &.bg-white + &.bg-white,
    &.bg-blue-5-tint + &.bg-blue-5-tint {
      @apply pt-0;
    }
  }
  // first matrix no top margin and last matrix no bottom margin class
  .matrix {
    &:first-child {
      .fm--ntm {
        @apply mt-0;
      }
    }

    &:last-child {
      .lm--nbm {
        @apply mb-0;
      }
      
      .lm--nbb {
        @apply border-b-0;
      }
    }
  }
}