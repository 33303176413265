@mixin accessible-focus-state() {
  outline: none;
  background-color: theme('colors.highlight') !important;
  color: theme('colors.blue.DEFAULT') !important;
  box-shadow: 
  // h-offset v-offset blur spread colour
  0px 3px 0px 0px theme('colors.blue.DEFAULT'),
  0px -3px 0px 0px theme('colors.highlight');
  text-decoration: none;

  &:hover  {
    background-color: theme('colors.highlight');
    color: theme('colors.blue.DEFAULT') !important;
    text-decoration: none;
  }

  &:not(.no-path-fill) {
    path {
      fill: theme('colors.blue.DEFAULT') !important;
    }

    &.two-colour-icon {
      path + path {
        fill: theme('colors.highlight') !important;
      }
    }
  }
}

a,
button,
.button,
.header-nav__panel--trigger {
  &:focus-visible {
    @include accessible-focus-state();
  }

  /* Fallback for browsers without :focus-visible support */
  @supports not selector(:focus-visible) {
    &:focus {
      @include accessible-focus-state();
    }
  }
}

button,
.button,
[type="submit"]:not(.button-reset) {
  &:focus-visible {
    box-shadow: none;
  }

  @supports not selector(:focus-visible) {
    &:focus {
      box-shadow: none;
    }
  }
}

.group {
  &:focus-visible, 
  &:focus-visible:hover {
    .link,
    .link-no-underline {
      color: theme('colors.blue.DEFAULT') !important;
      text-decoration: none;
    }
  }

  @supports not selector(:focus-visible) {
    &:focus, 
    &:focus:hover {
      .link,
      .link-no-underline {
        color: theme('colors.blue.DEFAULT') !important;
        text-decoration: none;
      }
    }
  }
}

.group:hover {
  .link,
  .link-no-underline {
    &:focus-visible {
      text-decoration: none;
    }
    @supports not selector(:focus-visible) {
      &:focus {
        text-decoration: none;
      }
    }
  }
}

.search-form {
  button {
    &:focus-visible {
      box-shadow:
        0px 3px 0px 0px theme('colors.blue.DEFAULT'),
        0px -3px 0px 0px theme('colors.highlight');
    }

    @supports not selector(:focus-visible) {
      &:focus {
        box-shadow:
          0px 3px 0px 0px theme('colors.blue.DEFAULT'),
          0px -3px 0px 0px theme('colors.highlight');
      }
    }
  }
}

.site-logo a {
  &:focus-visible {
    svg {
      @apply text-blue
    }
  }

  @supports not selector(:focus-visible) {
    &:focus {
      svg {
        @apply text-blue
      }
    }
  }
}

.link-with-icon-focus-style,
.site-logo a {
  &:focus-visible {
    box-shadow:
    0px -3px 0px 0px theme('colors.highlight'),
    0px 3px 0px 0px theme('colors.highlight'),
    0px 6px 0px 0px theme('colors.blue.DEFAULT');
  }

  @supports not selector(:focus-visible) {
    &:focus {
      box-shadow:
      0px -3px 0px 0px theme('colors.highlight'),
      0px 3px 0px 0px theme('colors.highlight'),
      0px 6px 0px 0px theme('colors.blue.DEFAULT');
    }
  }
}

.menu-level-1 {
  &:focus-visible {
    box-shadow: none;
  }

  @supports not selector(:focus-visible) {
    &:focus {
      box-shadow: none;
    }
  }
}

input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
input[type="radio"],
input[type="checkbox"],
select,
textarea {
  &:focus-visible {
    border-color: theme('colors.blue.10-darker');
    box-shadow:
    inset 0px 0px 0px 2px theme('colors.blue.10-darker'),
    0px 0px 0px 4px theme('colors.highlight');
  }

  @supports not selector(:focus-visible) {
    &:focus {
      border-color: theme('colors.blue.10-darker');
      box-shadow:
      inset 0px 0px 0px 2px theme('colors.blue.10-darker'),
      0px 0px 0px 4px theme('colors.highlight');
    }
  }
}

.visually-hidden {
  position: absolute;
  left: -9999em;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}

.skip-link {
  &:focus-visible {
    @include accessible-focus-state();
    position: fixed;
    top: 1em;
    left: 1em;
    width: auto;
    height: auto;
    z-index: 99;
  }

  @supports not selector(:focus-visible) {
    &:focus {
      @include accessible-focus-state();
      position: fixed;
      top: 1em;
      left: 1em;
      width: auto;
      height: auto;
      z-index: 99;
    }
  }
}

.quiz-answer > input[type="radio"] {
  &:focus-visible {
    box-shadow: none;
    background-color: theme('colors.highlight') !important;
    border-color: theme('colors.blue.10-darker') !important;
    &:before {
      @apply bg-[url('/dist/images/icons/checkbox-tick.svg')];
    }
    + label {
      @include accessible-focus-state();
      border: 0;
    }
  }
    
  /* Fallback for browsers without :focus-visible support */
  @supports not selector(:focus-visible) {
    &:focus {
      box-shadow: none;
      background-color: theme('colors.highlight') !important;
      border-color: theme('colors.blue.10-darker') !important;
      &:before {
        @apply bg-[url('/dist/images/icons/checkbox-tick.svg')];
      }
      + label {
        @include accessible-focus-state();
        border: 0;
      }
    }
  }
}