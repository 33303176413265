@font-face {
    font-family: 'Graphik';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("/dist/fonts/Graphik-Regular.woff2") format("woff2"),
         url("/dist/fonts/Graphik-Regular.woff") format("woff");
}
@font-face {
    font-family: 'Graphik';
    font-style:  normal;
    font-weight: 600;
    font-display: swap;
    src: url("/dist/fonts/Graphik-Semibold.woff2") format("woff2"),
         url("/dist/fonts/Graphik-Semibold.woff") format("woff");
}
@font-face {
    font-family: 'TitlingGothicFB';
    font-style:  normal;
    font-weight: 500;
    font-display: swap;
    src: url("/dist/fonts/TitlingGothicFBWide-Med.woff2") format("woff2"),
         url("/dist/fonts/TitlingGothicFBWide-Med.woff") format("woff");
}