input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  @apply hidden;
}

input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select,
textarea {
  @apply flex w-full min-h-[4.4rem] p-1 items-center appearance-none outline-none bg-white border-2 border-solid border-blue-75-tint max-w-xs md:max-w-sm mt-3 mb-2 rounded-none placeholder:text-secondary;

  &[aria-invalid="true"] {
    @apply border-[3px] border-coral-b;
  }
}

input[type="search"] {
  @apply my-0;
}

input[type="email"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select {
  @apply max-w-[384px];
}

select {
  position: relative;
  appearance: none;
  margin: 0;
  padding-right: 52px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url("/dist/images/icons/dropdown.svg");
  background-size: auto 75%;
  background-position: right 5px center;
}

// Hubspot
.hs-fieldtype-intl-phone {
  @apply flex flex-col md:flex-row gap-sm;

  input, select {
    &.hs-input {
      @apply my-0 md:mt-3 md:mb-2 w-full max-w-[384px];
    }
  }
}

// Form Assembly:
div[class*="field-container-"], 
// Hubspot:
div.hs-form-field, 
div.legal-consent-container,
// Formie plugin:
.fui-row:not(.fui-row-empty) {
  @apply mb-4 md:mb-4-lg;
}

/* Checkbox and radio button fields */
input[type="radio"],
input[type="checkbox"] {
  @apply inline-flex flex-shrink-0 w-[4.4rem] h-[4.4rem] justify-center items-center appearance-none outline-none bg-white border-2 border-solid border-blue-75-tint mr-3 mb-2 cursor-pointer;

  &:before {
    @apply content-[''] block opacity-0;
  }

  &:checked:before {
    @apply opacity-100;
  }
}

input[type="radio"] {
  @apply rounded-full;

  &:before {
    @apply w-[2rem] h-[2rem] rounded-full bg-blue;
  }

  .quiz-answer & {
    @apply w-[4rem] h-[4rem] checked:bg-blue checked:hover:bg-blue-5-darker;

    &:before {
      @apply w-[2.4rem] h-[1.8rem] bg-[url('/dist/images/icons/checkbox-tick-white.svg')] bg-no-repeat bg-center bg-contain bg-transparent;
    }
  }
}

input[type="checkbox"] {
  &:before {
    @apply w-[2.4rem] h-[1.8rem] bg-[url('/dist/images/icons/checkbox-tick.svg')] bg-no-repeat bg-center bg-contain;
  }
}

label {
  @apply block mb-1 max-w-xs md:max-w-sm;

  & + input[type="date"],
  & + input[type="email"],
  & + input[type="number"],
  & + input[type="password"],
  & + input[type="search"],
  & + input[type="tel"],
  & + input[type="text"],
  & + input[type="url"],
  & + select,
  & + textarea {
    @apply mt-3 mb-4;
  }
}

.field-hint {
  @apply block mb-3;
}

fieldset {
  @apply p-0 m-0 border-none appearance-none;
}

// Formie plugin:
div.fui-type-checkboxes, div.fui-type-radio {
  legend {
    @apply mb-2;
  }
}

// Hubspot:
.styled-lists ul.inputs-list li {
  @apply pl-0;

  &:before {
    @apply bg-none;
  }
}

// Form Assembly:
.wFormContainer .errFld,
// Hubspot:
.hs-form-field:has(.hs-error-msgs),
// Formie plugin:
.styled-lists .fui-error {
  @apply pl-[12px] border-l-[4px] border-l-coral-b;

  input {
    @apply border-coral-b border-2;
  }
}

// Form Assembly:
.wFormContainer .errMsg,
// Hubspot:
.hs-error-msgs label,
.legal-consent-container ul.hs-error-msgs label,
// Formie plugin:
ul.fui-errors li.fui-error-message {
  @apply text-blue font-semibold;

  &:before {
    @apply content-[''] inline-block w-[20px] h-[20px] mr-[6px] -mb-[2px];
    @apply bg-[url('/dist/images/icons/red-warning.svg')] bg-no-repeat bg-center bg-contain;
  }
}

h2.fui-title {
  @apply heading-md;
}
div.fui-instructions {
  @apply text-secondary;
  p {
    @apply mb-2;
  }
}