.site-header__burger-menu {
  &--close {
    display: none;
  }

  &.active {
    .site-header__burger-menu--open {
      display: none;
    }

    .site-header__burger-menu--close {
      display: block;
    }
  }
}

.site-header--nav {
  position: relative;
  // show/hide
  display: none;

  &.active {
    display: block;
  }
}

.header-nav__menu-bar,
.header-nav__panel,
.header-nav__sub,
.header-nav__main {
  & > li {
    &:before {
      content: none;
    }
  }
}

.header-nav__panel--trigger {
  cursor: pointer;

  svg {
    transition: all 0.2s ease;
  }

  &.active {
    box-shadow: inset 0 -6px 0 theme('colors.blue.5-tint'); // type h-offset v-offset blur spread colour

    svg {
      // TODO: Rotation direction is now off
      transform: rotate(270deg);
    }
  }
}

.header-nav__panel {
  // show/hide
  display: none;

  &.active {
    display: block;
  }
}

.header-nav--overlay {
  display: none;

  &.active {
    display: block;
  }
}