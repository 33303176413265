button,
.button,
.button-sub,
[type="button"],
[type="submit"] {
  &:not(.button-reset) {
    @apply inline-flex justify-center items-center font-semibold no-underline outline-none cursor-pointer px-[16px] pt-[12px] pb-[10px] md:px-[20px] md:pt-[15px] md:pb-[13px] border-0 border-b-4 border-solid border-b-blue-10-darker active:border-b-0 active:translate-y-[4px] active:mt-1 transition-all duration-200;

    &:not(:last-child) {
      @apply mr-[6px];
    }
  }
}

button,
.button,
[type="button"],
[type="submit"] {
  &:not(.button-reset) {
    @apply bg-blue text-white hover:bg-blue-5-darker hover:text-white;
  }
}

.button-sub, button.button-sub:not(.button-reset) {
  @apply bg-blue-20-tint text-blue hover:bg-blue-25-tint hover:text-blue;
}

.button-reset {
  @apply link inline bg-transparent border-none rounded-none leading-normal m-0 p-0 hover:bg-transparent;
}