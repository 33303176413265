@tailwind base;

@layer base {
  // links:

  a, .link, .default-link-colour {
    @apply text-links underline decoration-1 underline-offset-2 
    hover:text-links-hover hover:decoration-3 
    group-hover:text-links-hover group-hover:decoration-3
  }

  a.blue-link, .link.blue-link, .bg-coral-b-25-tint a {
    @apply text-blue hover:text-blue group-hover:text-blue;
  }

  .link-no-underline {
    @apply no-underline hover:underline hover:decoration-1 hover:underline-offset-2 
    group-hover:underline group-hover:decoration-1 group-hover:underline-offset-2;
  }

  // font family and weight:
  
  .heading-xxxl, 
  .heading-xxl,
  .heading-xl,
  .stat-number {
    @apply font-heading font-medium;
  }

  .heading-lg,
  .heading-md,
  .heading-sm,
  .heading-xs {
    @apply font-semibold;
  }

  // font size:
  
  .heading-xxxl {
    @apply text-xxxl-xs sm:text-xxxl md:text-xxxl-md lg:text-xxxl-lg;
  }

  .heading-xxl {
    @apply text-xxl-xs sm:text-xxl md:text-xxl-md lg:text-xxl-lg;
  }

  .heading-xl {
    @apply text-xl-xs sm:text-xl md:text-xl-md lg:text-xl-lg;
  }

  .heading-lg {
    @apply text-lg md:text-lg-lg;
  }

  .heading-md {
    @apply text-md md:text-md-lg;
  }

  .heading-sm {
    @apply text-base md:text-base-lg;
  }

  .stat-number {
    @apply text-stats-xs sm:text-stats md:text-stats-md;
  }

  .standfirst, .body-md {
    @apply text-md md:text-md-lg;
  }

  .body-sm {
    @apply text-base md:text-base-lg;
  }

  .body-xs, .heading-xs {
    @apply text-sm md:text-sm-lg;
  }

  // layout and spacing:
  
  h1 {
    @apply m-0;
  }

  .heading-xl {
    @apply max-w-md mt-6 md:mt-6-lg mb-4 md:mb-4-lg;
  }

  .heading-section {
    @apply mt-0 mb-7 md:mb-7-lg;
  }

  .heading-box {
    @apply mt-0 mb-3 md:mb-3-lg;
  }

  .heading-lg,
  .heading-md,
  .heading-sm {
    @apply max-w-xs md:max-w-sm mt-5 md:mt-5-lg mb-4 md:mb-4-lg;
  }

  p {
    @apply max-w-xs md:max-w-sm mb-4 md:mb-4-lg;

    &.standfirst {
      @apply mb-5 md:mb-5-lg;
    }
  }

  .highlighted-text *:last-child {
    @apply mb-0;
  }

  b, strong {
    @apply font-semibold;
  }

  .fc--ntm > *:first-child {
    @apply mt-0;
  }

  .lc--nbm > *:last-child {
    @apply mb-0;
  }

  h4 {
    @apply heading-md; // for content that has already been added using the redactor field
  }

  // styled lists:
  
  .styled-lists {
    ol, ul {
      @apply max-w-xs md:max-w-sm mb-4 md:mb-4-lg last:mb-0;
    }

    ul:not(.list-none) li {
      @apply relative pl-[22px] md:pl-[24px] mb-2 last:mb-0;

      &:before {
        @apply content-[""] bg-contain block absolute left-[1px] top-[6px] w-[8px] h-[8px] md:w-[9px] md:h-[9px] md:top-[9px];
        background-image: url('data:image/svg+xml,<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 8L0 7.67673L0.392733 0.172188L8 0V8Z" fill="#579E9C"/></svg>');
      }
    }

    ol {
      @apply [counter-reset:section];
    }
    ol li {
      @apply [counter-increment:section] relative pl-[22px] mb-2 last:mb-0 md:pl-[24px];
    }
    ol li:before {
      @apply [content:counters(section,'.')'.'] absolute left-0;
    }
  }

  // secondary colour text:
  .text-secondary {
    @apply text-blue-75-tint;
  }

  // blue background:
  .bg-blue {
    .text-secondary {
      @apply text-blue-25-tint;

      &.stat-caption {
        @apply text-white;
      }
    }

    a:not(.blue-link, .default-link-colour), 
    .link:not(.blue-link, .default-link-colour) {
      @apply text-white hover:text-white;
    }
  }

  // donation form widget:
  .donation-form iframe {
    @apply bg-white;
  }
}