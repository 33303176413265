@keyframes moveOut {
    0% {
        transform: translateX(0);
    }
    20% {
        transform: translateX(8px);
    }
    40% {
        transform: translateX(2px);
    }
    60% {
        transform: translateX(6px);
    }
    80% {
        transform: translateX(5px);
    }
    100% {
        transform: translateX(6px);
    }
}
@keyframes moveOutSmall {
    0% {
        transform: translateX(0);
    }
    20% {
        transform: translateX(4px);
    }
    40% {
        transform: translateX(1px);
    }
    60% {
        transform: translateX(3px);
    }
    80% {
        transform: translateX(2px);
    }
    100% {
        transform: translateX(3px);
    }
}
@keyframes rotate {
    0% {
        transform: translateX(0);
    }
    20% {
        transform: rotate(-5.482deg);
    }
    40% {
        transform: rotate(-4deg);
    }
    60% {
        transform: rotate(-5.482deg);
    }
    80% {
        transform: rotate(-4deg);
    }
    100% {
        transform: rotate(-5.482deg);
    }
}