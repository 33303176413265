#onetrust-banner-sdk {
    border-radius: 0 !important;
}
#onetrust-policy-text {
    font-size: 1rem !important;
    font-weight: theme('fontWeight.semibold') !important;
    text-align: center !important;

    a {
        color: theme('colors.coral-b.DEFAULT') !important;
        
        &:hover {
            color: theme('colors.blue.DEFAULT') !important;
            text-decoration: underline !important;
        }
    }
}

#onetrust-accept-btn-handler, .save-preference-btn-handler, #accept-recommended-btn-handler {
    background-color: theme('colors.blue.DEFAULT') !important;
    background-position: 90% center !important;
    border-radius: 0 !important;
    background-repeat: no-repeat !important;
    background-size: 32px !important;
    border: 0 !important;
    box-shadow: 7px 7px 5px 0 rgba(0, 0, 0, 0.1) !important;
    color: white !important;
    display: inline-block !important;
    font-weight: theme('fontWeight.semibold') !important;
    padding: 1em 1em * 1.2 !important;

    &:hover, &:active {
        background-color: theme('colors.coral-b.DEFAULT') !important;
        box-shadow: 7px 7px 5px 0 rgba(0, 0, 0, 0.1) !important;
        color: black !important;
    }
}
#onetrust-pc-btn-handler {
    background-color: theme('colors.green-a.DEFAULT') !important;
    color: theme('colors.blue.DEFAULT') !important;
    background-position: 90% center !important;
    border-radius: 0 !important;
    background-repeat: no-repeat !important;
    background-size: 32px !important;
    border: 0 !important;
    box-shadow: 7px 7px 5px 0 rgba(0, 0, 0, 0.1) !important;
    display: inline-block !important;
    font-weight: theme('fontWeight.semibold') !important;
    padding: 1em 1em * 1.2 !important;

    &:hover, &:active {
        background-color: theme('colors.blue.DEFAULT') !important;
        box-shadow: 7px 7px 5px 0 rgba(0, 0, 0, 0.1) !important;
        color: white !important;
        opacity: 1 !important;
    }
}
.ot-floating-button__open, .ot-floating-button__close {
    background-image: none !important;
    border-radius: 10px !important;
    padding: inherit !important;
    box-shadow: none !important;
    line-height: 10px !important;
}

#onetrust-pc-sdk {
    .ot-close-icon {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQ3Ljk3MSIgaGVpZ2h0PSI0Ny45NzEiIHZpZXdCb3g9IjAgMCA0Ny45NzEgNDcuOTcxIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0Ny45NzEgNDcuOTcxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBhdGggZD0iTTI4LjIyOCwyMy45ODZMNDcuMDkyLDUuMTIyYzEuMTcyLTEuMTcxLDEuMTcyLTMuMDcxLDAtNC4yNDJjLTEuMTcyLTEuMTcyLTMuMDctMS4xNzItNC4yNDIsMEwyMy45ODYsMTkuNzQ0TDUuMTIxLDAuODhjLTEuMTcyLTEuMTcyLTMuMDctMS4xNzItNC4yNDIsMGMtMS4xNzIsMS4xNzEtMS4xNzIsMy4wNzEsMCw0LjI0MmwxOC44NjUsMTguODY0TDAuODc5LDQyLjg1Yy0xLjE3MiwxLjE3MS0xLjE3MiwzLjA3MSwwLDQuMjQyQzEuNDY1LDQ3LjY3NywyLjIzMyw0Ny45NywzLDQ3Ljk3czEuNTM1LTAuMjkzLDIuMTIxLTAuODc5bDE4Ljg2NS0xOC44NjRMNDIuODUsNDcuMDkxYzAuNTg2LDAuNTg2LDEuMzU0LDAuODc5LDIuMTIxLDAuODc5czEuNTM1LTAuMjkzLDIuMTIxLTAuODc5YzEuMTcyLTEuMTcxLDEuMTcyLTMuMDcxLDAtNC4yNDJMMjguMjI4LDIzLjk4NnoiLz48L2c+PC9zdmc+") !important;
        background-size: 15px !important;
        box-shadow: none !important;
        margin-right: 20px !important;
    }
}

.notice {
    p {
        &:first-of-type {
            margin-top: 0;
        }
    }
}