.accordion {
  &__icon {
    @apply rotate-90;

    .expanded & {
      @apply -rotate-90;
    }
  }
}

.matrix--accordion + .matrix--accordion .accordion,
.accordion + .accordion {
  @apply border-t-0 -mt-[33px] md:-mt-[41px]; // spacer 6 + 1px to compensate for bottom border of previous accordion
}